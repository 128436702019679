import React from 'react'
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Icon from '../components/Icon'
import Footer from '../components/Footer'
import featuresData from '../data/features.json'

const FeaturesPage = ({ data }) => (
  <Layout path="/features" title="Features - Brentiv - Rental Software">
    <div className="features-page">
      <SmallHeader title="Features" />
      <div className="container-padded">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <Img
                className="img-fluid"
                alt="Orders"
                sizes={data.ordersImage.childImageSharp.sizes}
              />
            </div>
            <div className="col-lg-4 align-self-center">
              <div className="text-center text-lg-left">
                <h2 className="title">Orders</h2>
                <p>
                  Make reservations of products very easily and never rent
                  reserved products for other customers.
                </p>
                <p>
                  Manage your orders and check when products are not returned on
                  time. No more confusion and unhappy customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-padded light-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="text-center text-lg-left">
                <h2 className="title">Inventory Pricing</h2>
                <p>
                  Create a flexible rent pricing and we will calculate it
                  automatically according to selected rent period. It is
                  suitable for every kind of business.
                </p>
                <p>
                  Create tax rates to your shop and add it to your products if
                  necessary. We will calculate the taxes precisely for every
                  order so you will have correct data.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <Img
                className="img-fluid img-thumbnail shadow"
                alt="Price structures"
                sizes={data.priceStructuresImage.childImageSharp.sizes}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-padded">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <Img
                className="img-fluid"
                alt="Invoices"
                sizes={data.invoicesImage.childImageSharp.sizes}
              />
            </div>
            <div className="col-lg-4 align-self-center">
              <div className="text-center text-lg-left">
                <h2 className="title">Invoices</h2>
                <p>
                  Make orders as fast as possible – with our platform you will
                  not need to issue invoices separately – create invoice
                  directly from the order.
                </p>
                <p>
                  You can issue invoices per one click and change it if
                  necessary. Invoices are kept in the system so you can always
                  reach them whenever needed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-padded features">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center mb-4 pb-4">
              <h2 className="title">And there is more</h2>
              <p className="subtitle mb-4 pb-4">
                Brentiv rental software is created for everyone from the
                smallest to biggest rentals so we offer you plenty of great
                features applicable for all users.
              </p>
            </div>
          </div>
          <div className="row">
            {featuresData.features.map((feature, index) => (
              <div key={index} className="feature col-sm-6 col-md-3 mb-4">
                <Icon size="50" className="feature-icon" name={feature.icon} />
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer withContact />
    </div>
  </Layout>
)

export const query = graphql`
  query FeaturesQuery {
    ordersImage: file(
      relativePath: { eq: "pages/features/orders.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        sizes(maxWidth: 960) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
    priceStructuresImage: file(
      relativePath: { eq: "pages/features/pricing-structures.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        sizes(maxWidth: 960) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
    invoicesImage: file(
      relativePath: { eq: "pages/features/invoices.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        sizes(maxWidth: 960) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
  }
`
export default FeaturesPage
